import React from 'react';


export default function Logout() {
  localStorage.removeItem('token');
  window.location.replace(process.env.REACT_APP_WWW_BASE_URL)

  return (
    <></>
  )
}
