import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";

const Fitness = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/dashboard2" element={<Dashboard />} />
    </Routes>
  </BrowserRouter>
);

export default Fitness;
