export default function AppCard({ title, description, url }) {
    const urlWithToken = `${url}?token=${localStorage.getItem("token")}`

    return (
        <a href={urlWithToken}>
            <div className="text-left border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-2">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 pb-3">
                            {title}
                        </h3>
                        <p className="text-sm text-gray-500">
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </a>
    )
}
