import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import LoginForm from '../components/LoginForm';

async function loginUser(credentials) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .then(data => {
      return data.token
    })
}

export default function Login() {
  const [verified, setVerified] = useState(null);
  const [token, setToken] = useState(null);
  const [app, setApp] = useState(null);
  const [searchParams,] = useSearchParams();

  useEffect(() => {
    const app = searchParams.get("app");
    setApp(app);
  }, [searchParams]);

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, [token])

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/verify_token`, {
        method: 'POST',
        headers: {
          'Authorization': token,
        },
      })
      const json = await data.json();
      const verified = json.success

      setVerified(verified);
    }

    fetchData()
      .catch(console.error);
  }, [token]);

  const handleSubmit = async (email, password) => {
    const token = await loginUser({
      email,
      password
    });
    localStorage.setItem('token', token);
    setToken(token);
  }

  if (verified === null) {
    return <></>
  }

  if (verified === true) {
    let redirectUrl = `${process.env.REACT_APP_APP_BASE_URL}/?token=${token}`
    if (app === "fitness") {
      redirectUrl = `${process.env.REACT_APP_FITNESS_BASE_URL}/?token=${token}`
    } else if (app === "prayer") {
      redirectUrl = `${process.env.REACT_APP_PRAYER_BASE_URL}/?token=${token}`
    }

    window.location.href = redirectUrl;

    return <></>
  }

  return (
    <LoginForm onSubmit={handleSubmit} />
  )
}
