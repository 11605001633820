import axios from 'axios'
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/20/solid'



import { API_URL } from '../settings'
import { Container } from '../../../components/Container'


export default function PrayerAdd() {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [weight, setWeight] = useState(2)
  const mutation = useMutation(event => {
    event.preventDefault();
    const data = {
      name,
      description,
      weight,
    }

    // TODO: Wrap axios calls in library so I can abstract things like the toke
    // coming from localStorage, and figure out the redirects (like on an
    // expired token, and usig the refresh token)
    return axios.post(`${API_URL}/prayer`, data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
  })

  //if (mutation.isLoading) return <div>Adding Prayer...</div>
  if (mutation.isSuccess) return navigate("/prayers")

  return (
    <Container>
      <div className="bg-white rounded-md">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto h-full max-w-lg">
            <form onSubmit={mutation.mutate}>
              <div className="space-y-8">

                <div>
                  <h2 className="text-lg font-medium leading-6 text-gray-900">Add Prayer</h2>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                  <div className="sm:col-span-6">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Prayer
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="off"
                        className="block w-full min-w-0 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        defaultValue={description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="weight" className="block text-sm font-medium text-gray-700">
                      Priority
                    </label>
                    <div className="mt-1">
                      <select
                        id="weight"
                        name="weight"
                        autoComplete="off"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        defaultValue={weight}
                        onChange={e => setWeight(e.target.value)}
                      >
                        <option value={1}>Low</option>
                        <option value={2}>Medium</option>
                        <option value={3}>High</option>
                        <option value={4}>Urgent</option>
                      </select>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      How often do you want this prayer to show up in your prayer list?
                    </p>
                  </div>

                </div>

                {(mutation.isError) && (
                  <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">There was an error with your submission</h3>
                        <div className="mt-2 text-sm text-red-700">
                          <ul className="list-disc space-y-1 pl-5">
                            <li>prayer is a duplicate</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(mutation.isLoading) ? (
                  <>.</>
                ) : (
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                )}

              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  )
}
