import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container } from '../../../components/Container'
import { Header } from '../../../components/Header'
import AppCard from "../components/AppCard";


export default function Home() {
  const [token, setToken] = useState();
  const [apps, setApps] = useState([]);
  const [searchParams,] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    setToken(token);
    if (token) {
      localStorage.setItem("token", token);
      window.location.replace("/")
    }
  }, [searchParams]);

  useEffect(() => {
    const tokenString = localStorage.getItem('token');
    setToken(tokenString);
  }, [token]);

  useEffect(() => {
    // TODO: Get app list
    const fetchData = async () => {
      const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/apps`, {
        method: 'GET',
        headers: {
          'Authorization': token,
        },
      })
      const json = await data.json();
      setApps(json.apps.sort())
    }

    if (token) {
      fetchData()
        .catch(console.error);
    }

  }, [token]);

  const isSignedIn = (token)

  return (
    <>
      <Header isSignedIn={isSignedIn} />
      <main>
        <Container>
          <h1 className="text-center">App List</h1>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-5">
            {apps.map((app) => (
              <AppCard key={app.key} title={app.title} description={app.description} url={app.url} />
            )).sort()}
          </ul>
        </Container>
      </main>
    </>
  )
}
