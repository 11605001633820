import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import { Card } from '../../../components/Card'
import { Container } from '../../../components/Container'
import { EmptyState } from '../../../components/EmptyState'
import { Loader } from '../../../components/Loader'


const fetchCurrentPrayers = async ({ queryKey }) => {
  // TODO: Should I change this to use the queryKey for current_only and shuffle?
  // console.log("queryKey ", queryKey)
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/apps/prayer/prayer`, {
    params: {
      current_only: true,
      shuffle: true,
    },
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });

  return response.data.prayers
}

export default function Pray() {
  const { isLoading, error, data, refetch } = useQuery(
    ["prayers", [{ "current_only": true, "shuffle": true }]],
    fetchCurrentPrayers,
    {
      refetchOnWindowFocus: false,
    }
  );
  const prayers = data;

  if (error) return <div>Request Failed</div>
  if (isLoading) return <Loader />
  if (prayers.length === 0) return (
    <EmptyState
      text="Create a new prayer"
      url="/prayers/add"
    />
  )

  return (
    <Container>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => refetch()}
          >
            Shuffle
          </button>
        </div>
      </div>

      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-5">
        {(prayers.map(prayer => (
          <Card
            key={prayer.uuid}
            id={prayer.uuid}
            title={prayer.name}
            description={prayer.description}
            url={`/prayers/${prayer.uuid}/edit`}
          />
        )))}
      </ul>
    </Container>
  )
}
