export function Logo({ app }) {
  let logoUrl = "/logo.png"
  if (app) {
    logoUrl = `/logo-${app}.png`
  }
  return (
    <img
      src={logoUrl}
      alt="logo"
      style={{ height: 32 }}
      className="mx-auto"
    />
  )
}
