import { RecoilRoot } from 'recoil';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


import AppIndex from "./apps/app";
import Auth from "./apps/auth";
import Fitness from "./apps/fitness";
import Prayer from "./apps/prayer";
import WWW from "./apps/www";

const queryClient = new QueryClient()


export default function App() {
  const { hostname } = window.location

  if (
    !process.env.REACT_APP_API_BASE_URL ||
    !process.env.REACT_APP_APP_BASE_URL ||
    !process.env.REACT_APP_AUTH_BASE_URL ||
    !process.env.REACT_APP_FITNESS_BASE_URL ||
    !process.env.REACT_APP_PRAYER_BASE_URL ||
    !process.env.REACT_APP_WWW_BASE_URL
  ) {
    return <div>Application not configured correctly</div>
  }

  let appComponent = <WWW />
  if (hostname.startsWith("app.wireframe")) {
    appComponent = <AppIndex />
  } else if (hostname.startsWith("fitness.wireframe")) {
    appComponent = <Fitness />
  } else if (hostname.startsWith("prayer.wireframe")) {
    appComponent = <Prayer />
  } else if (hostname.startsWith("auth.wireframe")) {
    appComponent = <Auth />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        {appComponent}
      </RecoilRoot>
    </QueryClientProvider >
  )
};
