import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Navi from "./components/Navi";
import Pray from "./pages/Pray";
import PrayerAdd from "./pages/PrayerAdd";
import PrayerEdit from "./pages/PrayerEdit";
import PrayerList from "./pages/PrayerList";


const Prayer = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = `${process.env.REACT_APP_AUTH_BASE_URL}/login?app=prayer`
  }
  return (
    <BrowserRouter>
      <Navi />
      <Routes>
        <Route exact path="/" element={<Navigate to="/pray" replace={true} />} />
        <Route exact path="/pray" element={<Pray />} />
        <Route exact path="/prayers" element={<PrayerList />} />
        <Route exact path="/prayers/add" element={<PrayerAdd />} />
        <Route exact path="/prayers/:uuid/edit" element={<PrayerEdit />} />
      </Routes>
    </BrowserRouter>
  )
};

export default Prayer;
