import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'

import { API_URL } from '../settings'
import { Container } from '../../../components/Container'
import { EmptyState } from '../../../components/EmptyState'
import { Loader } from '../../../components/Loader'


const fetchAllPrayers = async () => {
  const res = await fetch(`${API_URL}/prayer`, {
    method: 'GET',
    headers: {
      'Authorization': localStorage.getItem("token"),
    },
  })

  return res.json()
}

export default function Home() {
  const { isLoading, error, data } = useQuery(["prayers"], fetchAllPrayers);
  if (error) return <div>Request Failed</div>;
  if (isLoading) return <Loader />;

  if (data.prayers.length === 0) return (
    <EmptyState
      text="Create a new prayer"
      url="/prayers/add"
    />
  )

  return (
    <Container>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Prayers</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/prayers/add"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Prayer
          </Link>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Prayer
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Priority
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.prayers.map((prayer) => {
                    let priority = "Unknown"
                    if (prayer.weight === 1) priority = "Low"
                    else if (prayer.weight === 2) priority = "Medium"
                    else if (prayer.weight === 3) priority = "High"
                    else if (prayer.weight === 4) priority = "Urgent"
                    return (
                      <tr key={prayer.uuid}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {prayer.name}
                          <br />
                          <span className="font-normal">{prayer.description}</span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{priority}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link to={`/prayers/${prayer.uuid}/edit`} className="text-indigo-600 hover:text-indigo-900">
                            Edit<span className="sr-only">, {prayer.name}</span>
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
