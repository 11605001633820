import { Link } from "react-router-dom";

export function NavLink({ href, children, mobile, onClick }) {
  let classNames = "inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
  if (mobile) {
    classNames = "block w-full p-2"
  }
  if (href?.startsWith("http://") || href?.startsWith("https://")) {
    return (
      <a
        href={href}
        className={classNames}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={href}
      className={classNames}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
