import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// import { useRecoilValue } from 'recoil';

import { Header } from '../../../components/Header'
import { naviItems } from '../settings'
// import { tokenAtom } from '../recoil/atoms'


// TODO: Can this be abstracted into a base class or shared component between
// apps? Should I do this on the index.js page?
export default function Navi() {
    const [token, setToken] = useState();
    const [searchParams,] = useSearchParams();
    // const tokenValue = useRecoilValue(tokenAtom);

    useEffect(() => {
        const token = searchParams.get("token");
        setToken(token);
        if (token) {
            localStorage.setItem("token", token);
            window.location.replace("/")
        }
    }, [searchParams]);

    useEffect(() => {
        const tokenString = localStorage.getItem('token');
        setToken(tokenString);
    }, [token]);

    const isSignedIn = (token)

    return (
        <Header app="prayer" isSignedIn={isSignedIn} items={naviItems} />
    )
}