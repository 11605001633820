export const naviItems = [
    {
        "title": "Pray Now",
        "url": "/pray",
    }, {
        "title": "Prayer List",
        "url": "/prayers",
    }
];

export const API_URL = `${process.env.REACT_APP_API_BASE_URL}/apps/prayer`;