import { Link } from "react-router-dom";

export default function Home() {
  console.log("loaded fitness")

  return (
    <>
      <div>Fitness Home</div>
      <Link to="/dashboard">Dashboard</Link>
    </>
  )
}
