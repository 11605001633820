import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";


const Apps = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = `${process.env.REACT_APP_AUTH_BASE_URL}/login`
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </BrowserRouter >
  );
}

export default Apps;
