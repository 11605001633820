import axios from 'axios'
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/20/solid'

import { API_URL } from '../settings'
import { Alert } from '../../../components/Alert'
import { Container } from '../../../components/Container'
import { Loader } from '../../../components/Loader'


const fetchPrayerById = async ({ queryKey }) => {
  const uuid = queryKey[1]
  const response = await axios.get(`${API_URL}/prayer/${uuid}`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });

  return response.data
}

const deletePrayerById = async (uuid) => {
  const response = await axios.delete(`${API_URL}/prayer/${uuid}`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });

  return response.data
}

export default function PrayerEdit() {
  let { uuid } = useParams();
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [weight, setWeight] = useState(2)

  const { isLoading, error } = useQuery(
    ["prayer", uuid],
    fetchPrayerById,
    {
      refetchOnWindowFocus: false,
      onSuccess: (prayer) => {
        setName(prayer.name);
        setDescription(prayer.description);
        setWeight(prayer.weight);
      },
      retry: (failureCount, error) => {
        if (error.response.status === 404) return false
        return true
      },
    }
  );

  const mutation = useMutation(event => {
    event.preventDefault();
    const data = {
      name,
      description,
      weight,
    }

    // TODO: Wrap axios calls in library so I can abstract things like the toke
    // coming from localStorage, and figure out the redirects (like on an
    // expired token, and usig the refresh token)
    return axios.patch(`${API_URL}/prayer/${uuid}`, data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
  })

  if (isLoading) return <Loader />
  if (error && error.response.status === 404) {
    return <Alert
      level="error"
      title="Not found"
      description="Whoops! This prayer was not found."
      actionText="Back to Prayer List"
      actionUrl="/prayers"
    />
  }

  if (error) return <div>An unknown error occurred</div>
  if (mutation.isSuccess) return navigate("/prayers")

  return (
    <Container>
      <div className="bg-white rounded-md">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto h-full max-w-lg">
            <form onSubmit={mutation.mutate}>
              <div className="space-y-8">

                <div>
                  <h2 className="text-lg font-medium leading-6 text-gray-900">Edit Prayer</h2>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Prayer
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="off"
                        className="block w-full min-w-0 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={e => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        defaultValue={description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="weight" className="block text-sm font-medium text-gray-700">
                      Priority
                    </label>
                    <div className="mt-1">
                      <select
                        id="weight"
                        name="weight"
                        autoComplete="off"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={e => setWeight(e.target.value)}
                        value={weight}
                      >
                        <option value={1}>Low</option>
                        <option value={2}>Medium</option>
                        <option value={3}>High</option>
                        <option value={4}>Urgent</option>
                      </select>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      How often do you want this prayer to show up in your prayer list?
                    </p>
                  </div>

                </div>

                {(mutation.isError) && (
                  <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">There was an error with your submission</h3>
                        <div className="mt-2 text-sm text-red-700">
                          <ul className="list-disc space-y-1 pl-5">
                            <li>{mutation.error}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(!mutation.isLoading) &&
                  <div>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                    <span
                      onClick={() => deletePrayerById(uuid).finally(() => navigate("/prayers"))}
                      className="cursor-pointer text-sm ml-5 text-red-600"
                    >
                      Delete Prayer
                    </span>
                  </div>
                }

              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  )
}
